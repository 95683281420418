import React, { useState, useEffect } from "react";
import { Box, Grid, Container, Typography, TextField } from "@material-ui/core";
import Image from "material-ui-image";
import { Trans, t } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useLingui } from "@lingui/react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SideMenu from "../../containers/asset-advertising/side-menu";
import AdSpaceModal from "./ad-space-modal";
import SearchIcon from "../../icons/search-icon";
import MobileFloatingMenu from "../../containers/asset-advertising/mobile-floating-menu";
import CheckBox from "../custom-checkbox/custom-checkbox-alternative-2";
import Footer from "../footer";
import AdSpaceCard from "../card/ad-space-card";
import GMapsPopup from "./map-popup";
import Link from "../link";
import theme from "../../theme";
import { getSpacePriceConfigFromSpot, getAdSpacePriceFromConfig } from "../../utils/spots";
import { getAssetSlug, isMobile } from "../../utils";
import { useQuery } from "/src/utils/index";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    justifyContent: "flex-start",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    maxWidth: "none",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  info: {
    alignSelf: "flex-start",
    width: "70%",
    color: theme.palette.black,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  galleryContainer: {
    width: "100%",
    height: 370,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: 280,
    },
  },
  galleryRightContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignContent: "space-between",
  },
  miniImageGallery: {
    display: "flex",
    flexDirection: "column",
  },
  imageRow: {
    display: "flex",
    flexDirection: "row",
  },
  dossierBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(1 / 2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.gray96,
    cursor: "pointer",
  },
  sidebar: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    width: 300,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
      padding: 0,
      width: "100%",
    },
  },
  sidebarTitle: {
    fontSize: 16,
    marginBottom: theme.spacing(2),
  },
  titleSteps: {
    fontSize: 14,
    fontWeight: 700,
  },
  steps: {
    fontSize: 12,
    color: theme.palette.gray71,
  },
  adSpacesContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& > div": {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  mapButtonContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    cursor: "pointer",
  },
  mapButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "170px",
    height: "41px",
    backgroundColor: theme.palette.successGreen,
  },
  divider: {
    width: "100%",
    borderBottom: `1.5px solid ${theme.palette.black}`,
  },
  dividerSoft: {
    width: "100%",
    borderBottom: `1.5px solid ${theme.palette.grayE5}`,
  },
  mapDosier: {
    color: theme.palette.menuGray,
    textTransform: "uppercase",
    textDecoration: "underline",
    cursor: "pointer",
  },
  dividerMapDosier: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRight: `1.5px solid ${theme.palette.menuGray}`,
    height: "16px",
  },
  searchIcon: {
    position: "absolute",
    top: 8,
    left: 8,
  },
  inputBase: {
    paddingLeft: 32,
    width: "100%",
    height: "40px",
    background: theme.palette.white,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      border: theme.borders[1],
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(0),
      borderBottom: theme.borders[1],
    },
  },
  adSpaceMenu: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  sidemenu: {},
  customInput: {
    width: 230,
    height: "auto",
    padding: 0,
    display: "flex",
    justifyContent: "center",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: 0,
    },
  },
  filterItemRow: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    border: `1px solid ${theme.palette.disabledRow}`,
    borderRadius: "5px",
  },
}));

function AssetAdvertising({
  region,
  showAdspaceCategories,
  asset,
  spots,
  spot,
  category,
  adSpaces = [],
  adSpacesCategories = [],
  assets = [],
  onChangeAsset,
  onChangeAdSpaceCategory,
  adSpacesConsultation = [],
  showAllAdSpaces = () => {},
  setSelectedAdSpaces = () => {},
}) {
  const query = useQuery();
  const spotTitle = query.get("spotTitle") || "";

  const classes = useStyles(theme);

  const { i18n } = useLingui();

  const [openMapsPopup, setOpenMapsPopup] = useState(false);
  const [openAdSpaceModal, setOpenAdSpaceModal] = useState(false);
  const [selectedAdSpace, setSelectedAdSpace] = useState({});
  const [showAll, setShowAll] = useState(false);

  const selectedSpot = spots.find((s) => s.slug === spot);
  const priceConfig = selectedSpot?.data?.price ?? getSpacePriceConfigFromSpot(spot);

  const assetMapAttachment =
    asset &&
    asset.attachments &&
    asset.attachments.length &&
    asset.attachments.find((attachment) => attachment.meta.type === "map");

  const mapCenter = asset ? { lat: asset.latitude, lng: asset.longitude } : null;

  const handleOpenAdSpaceModal = (adSpace) => {
    setSelectedAdSpace(adSpace);
    setOpenAdSpaceModal(true);
  };

  const handleSelectAdSpace = (ad) => {
    setSelectedAdSpaces(ad);
  };

  const filterAndSortAdSpaces = (adSpaces) => {
    return adSpaces.sort((a, b) => b.isAvailable - a.isAvailable);
  };

  useEffect(() => {
    showAllAdSpaces(showAll);
  }, [showAll]);

  return (
    <>
      <Container className={classes.container}>
        <AdSpaceModal
          showPrice={asset?.config.show_adspace_price}
          open={openAdSpaceModal}
          onClose={() => setOpenAdSpaceModal(false)}
          adSpace={selectedAdSpace}
        />
        <GMapsPopup
          open={openMapsPopup}
          center={mapCenter}
          adSpaces={adSpaces}
          onClose={() => {
            setOpenMapsPopup(false);
          }}
        />
        <Box display={["block", "flex"]} flexDirection="row" alignItems="flex-start" p={[1, 0]}>
          <Box className={classes.info}>
            <Box mb={2}>
              <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
                <Link color="inherit" to="/">
                  <Typography variant="h6" style={{ color: theme.palette.gray71 }}>
                    Home
                  </Typography>
                </Link>
                {region.name && (
                  <Link color="inherit" to={`/region-assets/${region.id}/`}>
                    <Typography variant="h6" style={{ color: theme.palette.gray71 }}>
                      {region.name}
                    </Typography>
                  </Link>
                )}
                <Link color="inherit" to={`/asset-spots/${getAssetSlug(asset)}/`}>
                  <Typography variant="h6" style={{ color: theme.palette.gray71 }}>
                    {asset?.name}
                  </Typography>
                </Link>
                <Typography variant="h6" style={{ color: theme.palette.black }}>
                  {spotTitle || <Trans>Advertising</Trans>}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              {asset && (
                <Link to={`/asset-spots/${getAssetSlug(asset)}`} style={{ textDecoration: "none" }}>
                  <Typography variant="h3">{spotTitle || <Trans>Advertising</Trans>}</Typography>
                </Link>
              )}

              {showAdspaceCategories && (
                <Box className={classes.filterItemRow}>
                  <Autocomplete
                    options={adSpacesCategories}
                    getOptionLabel={(option) => option.name}
                    className={classes.customInput}
                    onChange={(event, adSpaceCategory) => {
                      onChangeAdSpaceCategory(adSpaceCategory);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.customInput}
                        placeholder={category ? category.name : `${i18n._(t`Seleccionar categoría`)}`}
                      />
                    )}
                  />
                </Box>
              )}

              <Box className={classes.filterItemRow}>
                <Box mr={1} style={{ transform: "translateY(12%)" }}>
                  <SearchIcon width="20px" height="17px" />
                </Box>
                <Autocomplete
                  options={assets}
                  getOptionLabel={(option) => option.name}
                  className={classes.customInput}
                  onChange={(event, asset) => {
                    if (asset) {
                      onChangeAsset(asset);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} className={classes.customInput} placeholder={asset?.name} />
                  )}
                />
              </Box>
            </Box>
            <Box mt={1} className={classes.divider} />
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mt={2}>
              <Box display="flex" alignItems="center">
                <Typography variant="h1" style={{ display: "flex", alignItems: "center" }}>
                  {asset?.name}
                </Typography>
              </Box>
            </Box>

            <Box mt={2}>
              <Typography variant="body1">{asset?.description}</Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography variant="body2" style={{ fontStyle: "italic" }}>
                <Trans>*Los precios no incluyen IVA y otros impuestos</Trans>
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <>
                <a
                  target="_self"
                  onClick={() => {
                    setOpenMapsPopup(true);
                  }}
                  className={classes.mapDosier}
                >
                  <Typography variant="body1">
                    <Trans>Ver mapa</Trans>
                  </Typography>
                </a>
                <span className={classes.dividerMapDosier} />
              </>
              <a target="_blank" href={asset?.dossier_advertising} className={classes.mapDosier} rel="noreferrer">
                <Typography variant="body1">
                  <Trans>Dossier</Trans>
                </Typography>
              </a>
            </Box>
            <Box mt={2}>
              <Box mt={3 / 2} className={classes.dividerSoft} />
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mt={2}>
                <Typography variant="h5">
                  <Trans>Disponibles</Trans>: {adSpaces.reduce((acc, curr) => (curr.isAvailable ? (acc += 1) : acc), 0)}
                </Typography>

                <Box display="flex" flexDirection="row" alignItems="center">
                  <CheckBox
                    checked={showAll}
                    onChange={(e, checked) => {
                      setShowAll(checked);
                    }}
                  />
                  <Typography variant="body1">
                    <Trans>Mostrar no disponibles</Trans>
                  </Typography>
                </Box>
              </Box>
              <Box mt={2}>
                <Grid container spacing={3}>
                  {filterAndSortAdSpaces(adSpaces).map((ad) => (
                    <Grid key={ad.id} style={{ width: "100%" }} item md={4} sm={12}>
                      <Box display="flex" justifyContent="center">
                        <AdSpaceCard
                          key={ad.id}
                          images={ad.attachments[0]?.file_url}
                          title={ad.title}
                          priceConfig={priceConfig}
                          price={getAdSpacePriceFromConfig(ad, priceConfig)}
                          showAdSpacePrice={ad.asset?.config?.show_adspace_price}
                          onClickImage={() => handleOpenAdSpaceModal(ad)}
                          onClickCard={() => handleSelectAdSpace(ad)}
                          isDisabled={!ad.isAvailable}
                          isSelected={adSpacesConsultation.some((ads) => ads.id === ad.id)}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              {assetMapAttachment && (
                <Box style={{ width: "100%", height: "100%", minHeight: "600px" }}>
                  <Image
                    src={assetMapAttachment.file_url}
                    style={{
                      height: "100%",
                      padding: "0",
                      width: "100%",
                    }}
                    imageStyle={{
                      objectFit: "contain",
                      height: "100%",
                      minHeight: "600px",
                      width: "100%",
                      borderRadius: "5px",
                    }}
                  />
                </Box>
              )}

              {asset && (
                <Box display="flex" flexDirection="column" pt={4} gridGap={4} width="100%">
                  <Typography variant="h2" style={{ color: theme.palette.black }}>
                    {asset.seo_title}
                  </Typography>
                  <Typography variant="body1">{asset.seo}</Typography>
                </Box>
              )}
            </Box>
          </Box>
          {!isMobile() && (
            <Box display="flex" justifyContent="center" width="30%" position="relative">
              <SideMenu style={{ position: "fixed" }} />
            </Box>
          )}
        </Box>
      </Container>
      <MobileFloatingMenu />
      <Footer />
    </>
  );
}

export default AssetAdvertising;
